<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Name"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Ref No"
              label-for="v-ref-no"
            >
              <b-form-input
                id="v-ref-no"
                v-model="filters['ref_no']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <!-- input -->
            <b-row>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mt-1 mb-md-0"
              >
                <b-button
                  v-can="'zone-create'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="custom-button-color"
                  @click="onClickCreateZone"
                >
                  <feather-icon icon="PlusIcon" />
                  Add New
                </b-button>
              </b-col>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <ReportTableButtons
                  class="mt-2"
                  :items="rows"
                  :json_fieldz="json_fields"
                  :name="`Zone - Page ${page}.xls`"
                  :bulkname="`Zone.xls`"
                  :fetch="fetchZoneListNoPagination"
                  @refresh="onClickRefresh"
                />
              </b-col>
            </b-row>
          </div>

          <!-- table -->
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              :fields="fields"
              :items="rows"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              responsive
              striped
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.item.name)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div class="font-weight-bolder ml-1">
                      {{ data.item.name }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ data.item.ref_no }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(is_active)="data">
                {{ data.value }}
                <b-badge :variant="status[1][1]">
                  {{ status[0][1] }}
                </b-badge>
              </template>

              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="12"
                    />
                  </template>
                  <b-dropdown-item
                    v-can="'zone-edit'"
                    @click="onClickUpdate(data.item.id)"
                  >
                    <feather-icon icon="ToolIcon" />
                    Manage Zone
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-can="'zone-delete'"
                    @click="onClickDelete(data.item.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                    Delete Zone
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
          </b-overlay>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-sm-start"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-1 width-100 mr-2"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                md="9"
                class="d-flex align-items-center justify-content-sm-end"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :total-rows="(meta.total)? meta.total : 0"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </div>
      </b-card>
    </div>
    <set-zone ref="set_zone" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBadge,
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store'
import SetZone from '@/components/demarcation/SetZoneModal.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    BPagination,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    SetZone,
    BCardActions,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      filters: {},
      total: 0,
      meta: {},
      dataLoading: false,
      // Start BS Table
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      form: {},
      showUpdateModal: false,
      modelLoading: false,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      dir: false,
      recordId: 0,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'name',
          label: 'Zone',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'city_count',
          label: 'City Count',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      json_fields: {
        Zone: {
          field: 'name',
          callback: value => `"${value}"`,
        },
        'Ref No.': 'ref_no',
        'City Count': 'city_count',
      },
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    page() {
      this.fetchZoneList()
    },
    perPage() {
      this.fetchZoneList()
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchZoneList()
    this.totalRows = this.rows.length
  },
  methods: {
    onClickCreateZone() {
      this.$refs.set_zone.openModal(null, 'add')
    },
    async fetchZoneList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await ResourceRepository.getAllZoneList(this.page, this.filterQuery, this.perPage, this.sort))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchZoneListNoPagination() {
      try {
        const { data } = (await ResourceRepository.getAllZoneListNoPagination(this.filterQuery, this.sort))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async onClickUpdate(id) {
      this.$refs.set_zone.openModal(id, 'edit')
    },
    applyFilters() {
      this.fetchZoneList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchZoneList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.fetchZoneList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.fetchZoneList()
    },
    async onClickDelete(id) {
      return this.$swal({
        title: 'Confirm Delete',
        icon: 'info',
        text: 'Are you sure you want to delete this zone?',
        showCancelButton: true,
        confirmButtonText: 'Yes, confirm!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        backdrop: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.onClickConfirm(id)
        }
      })
    },
    async onClickConfirm(id) {
      try {
        const res = await ResourceRepository.deleteZone(id)
        if (res.status === 200) {
          await this.fetchZoneList()
          this.showSuccessMessage('Zone Deleted successfully')
        } else {
          this.showErrorMessage("Couldn't Delete Zone")
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
