<template>
  <div>
    <b-modal
      id="modal-zone-add"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      :title="modalTitle"
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        variant="light"
        opacity="0.30"
        blur="10px"
        rounded="sm"
      >
        <ValidationObserver
          ref="set-ZoneForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8"
            @submit.prevent="handleSubmit(setZone)"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Zone Name"
                  label-cols-md="4"
                  label-for="h-cName"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="zoneName"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Zone Name"
                      type="text"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="States"
                  label-for="addNewtreeview"
                  label-cols-md="3"
                >
                  <div class="control_wrapper">
                    <ejs-treeview
                      id="addNewtreeview"
                      :fields="fields"
                      :node-checked="nodeChecked"
                      :checked-nodes="selectedCities"
                      :show-check-box="true"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                v-can="'zone-edit'"
                offset-md="4"
              >
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1 custom-button-color"
                    type="submit"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Clear
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import initializeTreeviewStyles from '@/libs/syncfusion/ej2-vue-navigations/'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')

function initialState() {
  return {
    open: false,
    id: null,
    modalPurpose: 'add',
    loading: false,
    zoneName: '',
    fields: [],
    selectedCities: [],
    selectedCitiesCache: [],
    firstExpansion: true,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.modalPurpose === 'add' ? 'ADD NEW ZONE' : 'UPDATE ZONE'
    },
  },
  methods: {
    async openModal(id, purpose) {
      await Object.assign(this.$data, initialState())
      this.loading = true
      this.open = true
      this.modalPurpose = purpose
      this.id = id
      await this.fetchDataDistrictList()
      if (this.modalPurpose === 'edit') {
        await this.getZoneDetails()
      }
      initializeTreeviewStyles()
      this.listenToExpansion()
      this.loading = false
    },
    async getZoneDetails() {
      const { data } = (await ResourceRepository.getZoneDetails(this.id)).data
      this.zoneName = data.name
      this.selectedCities = data.cities.map(n => (n.id).toString())
      this.selectedCitiesCache = this.selectedCities
    },
    async fetchDataDistrictList() {
      try {
        const { data } = (await ResourceRepository.getDistrictList()).data
        const dataSource = []
        data.forEach(ds => {
          ds.cities.forEach(city => {
            // eslint-disable-next-line no-param-reassign
            city.pid = ds.id
            // eslint-disable-next-line no-param-reassign
            city.expanded = false
            // eslint-disable-next-line no-param-reassign
            city.isChecked = false
            dataSource.push(city)
          })
          // eslint-disable-next-line no-param-reassign
          ds.expanded = false
          // eslint-disable-next-line no-param-reassign
          ds.isChecked = false
          // eslint-disable-next-line no-param-reassign
          ds.hasChild = true
          dataSource.push(ds)
        })
        this.fields = {
          dataSource,
          id: 'id',
          parentID: 'pid',
          text: 'name',
          hasChildren: 'hasChild',
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    nodeChecked() {
      const addNewTreeObj = document.getElementById('addNewtreeview').ej2_instances[0]
      this.selectedCities = addNewTreeObj.checkedNodes
    },
    async setZone() {
      this.loading = true
      try {
        this.selectedCities = this.selectedCities.filter(n => !n.startsWith('S'))
        const payload = {
          name: this.zoneName,
          city_ids: this.selectedCities,
        }
        let res
        if (this.modalPurpose === 'add') {
          res = await ResourceRepository.createZone(payload)
        } else {
          res = await ResourceRepository.updateZone(this.id, payload)
        }
        if (res.status === 200 || res.status === 201) {
          this.showSuccessMessage(`Zone ${this.modalPurpose === 'add' ? 'Created' : 'Updated'} Successfully`)
          localStorage.removeItem('zone_list')
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage('Something went wrong')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    listenToExpansion() {
      if (this.modalPurpose === 'edit') {
        const elements = document.getElementsByClassName('e-icon-expandable')
        elements.forEach(element => {
          element.addEventListener('click', () => {
            if (this.firstExpansion) {
              const addNewTreeObj = document.getElementById('addNewtreeview').ej2_instances[0]
              addNewTreeObj.checkedNodes = this.selectedCitiesCache
              this.firstExpansion = false
            }
          })
        })
      }
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
