import { render, staticRenderFns } from "./ZoneManage.vue?vue&type=template&id=1affe532&scoped=true"
import script from "./ZoneManage.vue?vue&type=script&lang=js"
export * from "./ZoneManage.vue?vue&type=script&lang=js"
import style0 from "./ZoneManage.vue?vue&type=style&index=0&id=1affe532&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1affe532",
  null
  
)

export default component.exports